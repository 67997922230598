import React, { createContext, useState } from "react";
export const AuthContext = createContext();

const AuthProvider = (props) => {
  //State para el correo electrónico
  const [CorreoElectronico, setCorreoElectronico] = useState(false);
  const [informacion, setInformacion] = useState({
    nombre: "",
    CorreoElectronico: "",
    contrasena: "",
  });

  return (
    <AuthContext.Provider
      value={{
        CorreoElectronico,
        setCorreoElectronico,
        informacion,
        setInformacion,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};


export default AuthProvider;
