// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Sector = ({icono, contenido, fondo}) => {
    return (  
        <div className="sector">
          <div className="ilustracion">
          <img className="sector-fondo" src={fondo}/>
          <img className="sector-icono"src={icono}/>
          </div>
          <p>{contenido}</p>
        </div>
    );
}
 
export default Sector;