// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { Button } from "@mui/material";
import Paso from "./Paso";
const Pasos = () => {
  const pasos = [
    {
      imagen: "imgs/paso_1.webp",
      contenido: "Llenar formulario de registro",
    },
    {
      imagen: "imgs/paso_2.webp",
      contenido: "Completar autodiagnostico",
    },
    {
      imagen: "imgs/paso_3.webp",
      contenido: "Subir tu documentación",
    },
    {
      imagen: "imgs/paso_4.webp",
      contenido: "Proceso de Evaluación",
    },
    {
      imagen: "imgs/paso_5.webp",
      contenido: "Ya eres ¡Chihuahua Market!",
    },
  ];
  return (
    <div className="contenedor-pasos">
      <div className="pasos-titulo">
        <h1>Pasos para obtener tu distintivo</h1>
      </div>
      <div className="pasos-contenido">
          {pasos.map((paso, index) => (
            <Paso key={index} imagen={paso.imagen} contenido={paso.contenido} />
          ))}
      </div>
      <Button className="boton3" variant="contained" color="primary" onClick={() => window.open("https://docs.google.com/forms/d/1A2I1gB5d-eDbdzcgdSknK1yVAsHTIDyPpLVXUfsBfG8/viewform?chromeless=1&edit_requested=true","_blank")}>
        ¡Aplica Aquí!
      </Button>
    </div>
  );
};

export default Pasos;
