// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Sector = ({imagen, contenido}) => {
    return (  
        <div className="contenido">
          <img src={imagen}/>
          <p>{contenido}</p>
        </div>
    );
}
 
export default Sector;