// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import Slider from "react-slick";
const SliderMovil = ({settings,imagenes}) => {
    return ( 
        <div className="slider2">
        <Slider {...settings}>
          {imagenes.map((imagen, index) => (
            <div key={index} className="slide2">
              <img src={imagen.image} alt={`Slide ${index}`} />
              <div className="slide-content2">
                <div className="texto-slider2"></div>
                <div></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
     );
}
 
export default SliderMovil;