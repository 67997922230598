import Recuperacion from "../../Componentes/RecuperarCuenta/Recuperacion";
const RecuperarCuenta = () => {
  return (
    <>
      <main className="recuperacion">
        <h1>Recuperar Cuenta</h1>
        <Recuperacion />

      </main>
    </>
  );
};

export default RecuperarCuenta;
