import CampoTexto from "../../../../../Componentes/CampoTexto/CampoTexto";
import Desplegable from "../../../../../Componentes/Desplegable/Desplegable";
const Etapa2 = ({datosEmpresa,setDatosEmpresa,cargando}) => {
    const CuestionarioTiendaEnLinea = () => {
        return (
          <CampoTexto
            state={datosEmpresa}
            setState={setDatosEmpresa}
            nombre="URLTiendaEnLinea"
            label="¿Cuál es la URL de la tienda en línea?"
            placeholder="https://www.tiendaenlinea.com"
            tipo="text"
            requerido={datosEmpresa.TiendaEnLinea}
            multilinea={false}
            deshabilitado={false}
            cargando={cargando}
          />)
      }
      const CuestionarioRedesSociales = () => {
        return  (<>
        <CampoTexto
          state={datosEmpresa}
          setState={setDatosEmpresa}
          nombre="Twitter"
          label="Twitter"
          placeholder="https://twitter.com/empresa"
          tipo="text"
          requerido={datosEmpresa.RedesSociales}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosEmpresa}
          setState={setDatosEmpresa}
          nombre="Facebook"
          label="Facebook"
          placeholder="https://facebook.com/empresa"
          tipo="text"
          requerido={datosEmpresa.RedesSociales}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosEmpresa}
          setState={setDatosEmpresa}
          nombre="Instagram"
          label="Instagram"
          placeholder="https://instagram.com/empresa"
          tipo="text"
          requerido={datosEmpresa.RedesSociales}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosEmpresa}
          setState={setDatosEmpresa}
          nombre="LinkedIn"
          label="LinkedIn"
          placeholder="https://linkedin.com/empresa"
          tipo="text"
          requerido={datosEmpresa.RedesSociales}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        </>)
      }
    return ( 
        <>
             <Desplegable etiqueta="¿La empresa cuenta con tienda en línea" mostrarContenedor={datosEmpresa} setMostrarContenedor={setDatosEmpresa} campo={"TiendaEnLinea"} children={<CuestionarioTiendaEnLinea/>} />
      <Desplegable etiqueta="¿La empresa tiene redes sociales?" mostrarContenedor={datosEmpresa} setMostrarContenedor={setDatosEmpresa} campo={"RedesSociales"} children={<CuestionarioRedesSociales/>} />

        </>
     );
}
 
export default Etapa2;