// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Objetivo = () => {
  return (
    <section className="objetivo">
      <div className="img">
        <img src="/imgs/Logo_chihuahuamkt.webp" alt="objetivo" />
      </div>

      <div className="contenedor-texto">
        <h2>OBJETIVO</h2>

        <p>
          El distintivo <span>¡Chihuahua Market!</span> es un medio para
          identificar el talento y la mano de obra de los chihuahuenses buscando
          privilegiar el consumo de los productos y servicios de Chihuahua,
          brindando un sello característico y elevando un sentimiento de
          pertenencia arraigado en la cultura regional.
        </p>
      </div>
    </section>
  );
};

export default Objetivo;
