import CampoTexto from "../CampoTexto/CampoTexto";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Colonias = (
  state,
  setState,
  campoColonia,
  campoCp,
  campoMunicipio,
  cargando
) => {
  return (
    <>
      <div className="contenedor-doble">
        <CampoTexto
          state={state}
          setState={setState}
          nombre={campoCp}
          label="Código Postal"
          placeholder={"00000"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={false}
        />
        <FormControl fullWidth>
          <InputLabel>Colonia</InputLabel>
          <Select fullWidth size="small">
            <MenuItem>Colonia</MenuItem>
          </Select>
        </FormControl>
      </div>
      <FormControl fullWidth>
          <InputLabel>Municipio</InputLabel>
          <Select disabled fullWidth size="small">
            <MenuItem>Colonia</MenuItem>
          </Select>
        </FormControl>
    </>
  );
};

export default Colonias;
