// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { TextField, Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRequest from "../../Hooks/useRequest/useRequest";
import Cookies from "universal-cookie";

const IniciarSeion = () => {
  const iniciarSesion = useRequest("/api/auth");
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);
  const [informacion, setInformacion] = useState({
    CorreoElectronico: "",
    Contrasena: "",
  });

  const [errorInformacion, seterrorInformacion] = useState({
    TipoError: false,
    msg: "",
  });

  const cookies = new Cookies();

  const InicioSesion = async (e) => {
    e.preventDefault();
    console.log(informacion);
    console.log(iniciarSesion);
    setCargando(true);
    const response = await iniciarSesion.post(informacion);
    setCargando(false);
    if (response.status === 200) {
      console.log(response.data);
      cookies.set("token", response.data.token, {
        path: "/",
        expires: new Date(response.data.expiracion),
      });

      navigate("/Inicio");
    } else if (response.status === 401) {
      console.log(response.data);
      if (response.data.TipoError === "CorreoElectronico") {
        seterrorInformacion({
          TipoError: "CorreoElectronico",
          msg: response.data.error,
        });
      } else if (response.data.TipoError === "Contrasena") {
        seterrorInformacion({
          TipoError: "Contrasena",
          msg: response.data.error,
        });
      }
    }
  };

  return (
    <main className="auth">
      <section
        className="auth-container"
           
      >
        <div className="contenedor-arte">
          <img src="/auth/login.webp" alt="auth" />
        </div>
        <div className="contenedor-movil" style={{display: "none"}}>

        <h1>  Bienvenido a <span>!Chihuahua Market!</span></h1>

        </div>
        <div className="contenedor-formulario">
          <form onSubmit={(e) => InicioSesion(e)}>
            <h1>Iniciar Sesión</h1>
            <TextField
              label="Correo Electrónico"
              placeholder="correo@ejemplo.com"
              required
              value={informacion.CorreoElectronico}
              onChange={(e) =>
                setInformacion({
                  ...informacion,
                  CorreoElectronico: e.target.value,
                })
              }
              error={
                errorInformacion.TipoError == "CorreoElectronico" ? true : false
              }
              helperText={
                errorInformacion.TipoError == "CorreoElectronico"
                  ? errorInformacion.msg
                  : ""
              }
            />

            <TextField
              label="Contraseña"
              type="password"
              required
              value={informacion.Contrasena}
              onChange={(e) =>
                setInformacion({
                  ...informacion,
                  Contrasena: e.target.value,
                })
              }
              error={errorInformacion.TipoError == "Contrasena" ? true : false}
              helperText={
                errorInformacion.TipoError == "Contrasena"
                  ? errorInformacion.msg
                  : ""
              }
            />

            <Button style={{backgroundColor: "#D6506D"}} type="submit" variant="contained" disabled={cargando}>
              {cargando ? "Cargando..." : "Iniciar Sesión"}
            </Button>
            <p onClick={() => navigate("/Auth/Recuperar-Cuenta")}>Olvidé la contraseña</p>
          </form>
          <hr />
          <p>
            ¿No tienes una cuenta?
            
          </p>
          <p>
          <span onClick={() => navigate("/Auth/Crear-Cuenta")}>Crear una</span>
          </p>
        </div>
      </section>
    </main>
  );
};

export default IniciarSeion;
