import OpcionMultiple from "../../../../Componentes/OpcionMultiple/OpcionMultiple";
import CampoTexto from "../../../../Componentes/CampoTexto/CampoTexto";
import Colonias from "../../../../Componentes/Colonias/Colonias";
import { Button } from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const DatosComerciales = () => {
  const navigate = useNavigate();
    const [cargando, setCargando] = useState(false);

    const actividades = [
        { Id: 1, ActividadComercial: "Alimentos y bebidas" },
        { Id: 2, ActividadComercial: "Servicios" },
        { Id: 3, ActividadComercial: "Restaurantes" },
        { Id: 4, ActividadComercial: "Productos de belleza y cuidado personal" },
        { Id: 5, ActividadComercial: "Moda y calzado" },
        { Id: 6, ActividadComercial: "Proveedores de la industria" },
        { Id: 7, ActividadComercial: "Artesanías" },
      ];
    
      const [datosComerciales, setDatosComerciales] = useState({
        ActividadComercial: 1,
        Calle: "",
        NumeroExterior: "",
        NumeroInterior: "",
        Colonia: "",
        CodigoPostal: "",
        Municipio: "",
      });
    return ( 
      <>
      <div className="secuencia-etapas">
        <div   onClick={() => navigate("/Portal/Formulario")}  className="etapa-secuencia">
        <FontAwesomeIcon icon={faCheckCircle} />
        <p>Datos Fiscales</p>
        </div>
       <FontAwesomeIcon icon={faChevronRight} />
        <div  onClick={() => navigate("/Portal/Formulario/Datos-Empresa")}className="etapa-secuencia">
        <FontAwesomeIcon icon={faCircle} />
        <p>Datos de la Empresa</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
        <div className="etapa-secuencia activo">
        <FontAwesomeIcon icon={faCircle} />
        <p>Datos Comerciales</p>
        </div>
      </div>
      <div className="etapa">
        <form>
        <h1>Datos Comerciales</h1>
        <p>Llena los siguientes campos con la información de tu comercialización</p>
        <OpcionMultiple
          state={datosComerciales}
          setState={setDatosComerciales}
          nombre="ActividadComercial"
          elementos={actividades}
          identificador={"Id"}
          valor={"ActividadComercial"}
          etiqueta={"La empresa es:"}
        />
       
        <CampoTexto
          state={datosComerciales}
          setState={setDatosComerciales}
          nombre="Calle"
          label="Calle"
          placeholder={"Domicilio Fiscal"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosComerciales}
          setState={setDatosComerciales}
          nombre="NumeroExterior"
          label="Número Exterior"
          placeholder={"000"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosComerciales}
          setState={setDatosComerciales}
          nombre="NumeroInterior"
          label="Número Interior"
          placeholder={"000"}
          tipo="text"
          requerido={false}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />

        <Colonias
          state={datosComerciales}
          setState={setDatosComerciales}
          campoColonia={"Colonia"}
          campoCp={"CodigoPostal"}
          campoMunicipio={"Municipio"}
          cargando={cargando}
        />
         <Button onClick={() => navigate("Datos-Empresa")} variant="contained">Continuar</Button>
      </form>
      </div>
      </>
     );
}
 
export default DatosComerciales;