// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const ParaQueSirve = () => {
    return (         <div className="seccion">
    <div className="contenedor-imagen">
        <img src="imgs/header_seccion_1.webp" alt="arte-seccion" />
    </div>

    <div className="cuerpo-seccion">
        <div className="titulo-paraquesirve">
        <h3>¿Para qué me sirve?</h3>
        </div>
        

        <p>
        Es un distintivo para identificar y posicionar los productos y servicios hechos en el Estado de Chihuahua
        </p>
    </div>
</div> );
}
 
export default ParaQueSirve;