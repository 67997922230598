// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import Beneficios from "./Componentes/Beneficios";
import Colaboradores from "./Componentes/Colaboradores";
import Objetivo from "./Componentes/Objetivo";
import Resumen from "./Componentes/Resumen";

const QueEs = () => {
  return (
    <main className="que-es">
      <Resumen />
      <Objetivo />
      <Beneficios />
      <Colaboradores />
    </main>
  );
};

export default QueEs;
