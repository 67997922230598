// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Beneficios = () => {
  return (
    <section className="beneficios">
      <h2>
        ¿Qué beneficios obtengo al tener mi distintivo ¡CHIHUAHUA MARKET!?
      </h2>

      <p>
        Lo que promoverá las ventas y la competitividad de los productos y
        servicios que cuenten con este distintivo.  
      </p>

      <div className="contenedor-beneficios">
        <div className="beneficio">
          <div className="icono">
            <img src="/imgs/icono_1.webp" alt="icono" />
          </div>
          <h4>Acceso a plataformas de venta en línea</h4>
        </div>
        <div className="beneficio">
          <div className="icono">
          <img src="/imgs/icono_5.webp" alt="error" />
          </div>
          <h4>Participación en ferias de comercialización locales, nacionales e internacionales</h4>
        </div>
        <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_2.webp" alt="error" />
          </div>
          <h4>Subsidio de imagen de propiedad intelectual</h4>
        </div>
        <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_9.webp" alt="error" />
          </div>
          <h4>Acceso a programas de capacitacion</h4>
        </div>   <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_3.webp" alt="error" />
          </div>
          <h4>Networking y encuentros de negocios</h4>
        </div>
        <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_7.webp" alt="error" />
          </div>
          <h4>Acceso a programas de incubación y aceleración de negocios</h4>
        </div>
        <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_4.webp" alt="error" />
          </div>
          <h4>Promoción y difusión a través de campañas publicitarias</h4>
        </div>
        <div className="beneficio">
        <div className="icono">
          <img src="/imgs/icono_8.webp" alt="error" />
          </div>
          <h4>Desarrollo de la oferta exportable</h4>
        </div>
      </div>
    </section>
  );
};

export default Beneficios;
