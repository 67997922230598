import { faDoorOpen, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
    const navigate = useNavigate();
    return ( 
        <nav className="nav-portal">
            <h1 onClick={() => navigate("/Portal")}>Chihuahua Market</h1>

            <div className="contendor-opciones">
                <p>Nombre del Solicitante</p>
                <FontAwesomeIcon icon={faGear}  />
                <FontAwesomeIcon icon={faDoorOpen}  />
            </div>
        </nav>
     );
}
 
export default NavBar;