import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

const DatePicker = ({ state, setState, nombre, etiqueta }) => {
  let fecha = state[nombre];
  //Comprobar que la fecha tenga el formato correcto usando una expresión regular

  const [dia, setDia] = useState(1);
  const [mes, setMes] = useState(1);
  const [ano, setAno] = useState(2001);

  const meses = [
    { Id: 1, Nombre: "Enero" },
    { Id: 2, Nombre: "Febrero" },
    { Id: 3, Nombre: "Marzo" },
    { Id: 4, Nombre: "Abril" },
    { Id: 5, Nombre: "Mayo" },
    { Id: 6, Nombre: "Junio" },
    { Id: 7, Nombre: "Julio" },
    { Id: 8, Nombre: "Agosto" },
    { Id: 9, Nombre: "Septiembre" },
    { Id: 10, Nombre: "Octubre" },
    { Id: 11, Nombre: "Noviembre" },
    { Id: 12, Nombre: "Diciembre" },
  ];

  function diasEnMes(anio, mes) {
    // Ten en cuenta que los meses en JavaScript van de 0 a 11, por lo que restamos 1 al mes proporcionado.
    var fecha = new Date(anio, mes - 1, 1);

    // Configuramos la fecha al último día del mes.
    fecha.setMonth(fecha.getMonth() + 1);
    fecha.setDate(fecha.getDate() - 1);

    // Devolvemos el día del mes, que nos dará el número total de días en el mes.

    return fecha.getDate();
  }

  const cambiarAno = (e) => {
    setState({
      ...state,
      [nombre]: `${e.target.value}-${state[nombre].split("-")[1]}-01`,
    });
  };

  const cambiarMes = (e) => {
    if (e.target.value > 9) {
      setState({
        ...state,
        [nombre]: `${state[nombre].split("-")[0]}-${e.target.value}-01`,
      });
    } else {
      setState({
        ...state,
        [nombre]: `${state[nombre].split("-")[0]}-0${e.target.value}-01`,
      });
    }
  };

  const cambiarDia = (e) => {
    if (e.target.value > 9) {
      setState({
        ...state,
        [nombre]: `${state[nombre].split("-")[0]}-${
          state[nombre].split("-")[1]
        }-${e.target.value}`,
      });
    } else {
      setState({
        ...state,
        [nombre]: `${state[nombre].split("-")[0]}-${
          state[nombre].split("-")[1]
        }-0${e.target.value}`,
      });
    }
  };

  useEffect(() => {
    if (!/^\d{0,4}-\d{2}-\d{2}$/.test(state[nombre])) {
      setState({ ...state, [nombre]: "2001-01-01" });
    }
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state[nombre]]);

  return (
    <div className="date-picker">
        <InputLabel>{etiqueta}</InputLabel>
      <div className="contenedor-fecha">
        <FormControl fullWidth>
          <InputLabel>Día</InputLabel>
          <Select
            size="small"
            value={
              !/^\d{0,4}-\d{2}-\d{2}$/.test(state[nombre])
                ? 1
                : Number(state[nombre].split("-")[2])
            }
            onChange={(e) => cambiarDia(e)}
          >
            {!/^\d{0,4}-\d{2}-\d{2}$/.test(state[nombre])
              ? Array.from(Array(diasEnMes(2021, 4)).keys()).map((dia) => (
                  <MenuItem key={dia} value={dia + 1}>
                    {dia + 1}
                  </MenuItem>
                ))
              : Array.from(
                  Array(
                    diasEnMes(
                      state[nombre].split("-")[0],
                      state[nombre].split("-")[1]
                    )
                  ).keys()
                ).map((dia) => (
                  <MenuItem key={dia} value={dia + 1}>
                    {dia + 1}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Mes</InputLabel>
          <Select
            size="small"
            value={
              !/^\d{0,4}-\d{2}-\d{2}$/.test(state[nombre])
                ? 1
                : Number(state[nombre].split("-")[1])
            }
            onChange={(e) => cambiarMes(e)}
          >
            {meses.map((mes) => (
              <MenuItem key={mes.Id} value={`${mes.Id}`}>
                {mes.Nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          size="small"
          onChange={(e) => cambiarAno(e)}
          value={
            !/^\d{0,4}-\d{2}-\d{2}$/.test(state[nombre])
              ? 2001
              : Number(state[nombre].split("-")[0])
          }
          label="Año"
          error={!/^\d{4}-\d{2}-\d{2}$/.test(state[nombre])}
          helperText={
            !/^\d{4}-\d{2}-\d{2}$/.test(state[nombre])
              ? "El año debe ser 4 dígitos"
              : ""
          }
          placeholder="2000"
        />
      </div>
    </div>
  );
};

export default DatePicker;
