// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const MercadoLibre = () => {
    const llamarNumero = () => {
        window.location.href = 'tel:6144423329'; 
      };
    return ( 
        <div className="seccion">
        <div className="contenedor-imagen">
            <img src="imgs/header_seccion_2.webp" alt="arte-seccion" />
        </div>

        <div className="cuerpo-seccion">
            <div className="titulo-mercadolibre">
            <h3>!Vende desde casa!</h3>
            </div>
            <p>
            Únete a la tienda en linea "Chihuahua Market"
            </p>
            <span>
                Nosotros te decimos como
            </span>
            <button onClick={llamarNumero}>Llama aquí</button>
        </div>
    </div>
     );
}
 
export default MercadoLibre;