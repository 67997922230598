import { useState } from "react";

import DatosFiscales from "./EtapasFormulario/DatosFiscales/DatosFiscales";
import { Outlet } from "react-router-dom";
const FormularioRegistro = () => {
  const [elementos, setElementos] = useState([
    { Id: 1, Nombre: "Juan" },
    { Id: 2, Nombre: "Pedro" },
    { Id: 3, Nombre: "Pablo" },
  ]);


  const [formulario, setFormulario] = useState({
    Elemento: 1,
    Nombre: "",

    Fecha: false,
  });


  return (
    <section className="formulario-registro etapa-formulario">
      <Outlet />
    </section>
  );
};

export default FormularioRegistro;
