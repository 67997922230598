// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Vigencia = () => {
    return (
        <div className="contenedor-vigencia">
        <div className="vigencia">
          <h1>VIGENCIA</h1>
          <div className="vigencia-duracion">
            <p> 6 años</p>
          </div>
        </div>
        <div className="disclaimer-vigencia">
          <p>
            Con opcion a renovacion cumpliendo nuevamente con los requisitos
            solicitados en la primera ocasion
          </p>
        </div>
      </div>

    );
}

export default Vigencia;