import { Button } from "@mui/material";
import { useState } from "react";

const useEtapasFormulario = (etapas = []) => {
  const [etapaSeleccionada, setEtapaSeleccionada] = useState(0);
  const siguienteEtapa = (e) => {
    e.preventDefault()
    if (etapaSeleccionada == etapas.length - 1) {
      return false;
    } else {
      setEtapaSeleccionada(etapaSeleccionada + 1);
    }
  };
  const anteriorEtapa = () => {
    if (etapaSeleccionada == 0) {
      return false;
    } else {
      setEtapaSeleccionada(etapaSeleccionada - 1);
    }
  };

  const cambiarEtapa = (etapa) => {
    if (etapa <= etapaSeleccionada) {
      setEtapaSeleccionada(etapa);
    }
  };

  const Indicadores = () => {
    return (
      <div className="contenedor-indicador-etapas">
        {etapas.map((etapa, index) => {
          return (
            <div
              onClick={() => cambiarEtapa(index)}
              className={`indicador-etapa ${
                index == etapaSeleccionada ? "etapa-seleccionada" : ""
              }`}
              key={index}
            ></div>
          );
        })}
      </div>
    );
  };

  const EtapaActual = () => {
    return (
      <div className="etapa">
        <h1>Datos de la Empresa</h1>
        <p>Llena los siguientes campos con la de tu negocio</p>
       <Indicadores/>
        <form onSubmit={e => siguienteEtapa(e)}>
        {etapas[etapaSeleccionada]}
        <div className="contenedor-doble">
            <Button disabled={etapaSeleccionada === 0} onClick={() => anteriorEtapa()} variant="contained">Atras</Button>
            <Button type="submit" variant="contained">Siguiente</Button>
        </div>
        </form>
        
      </div>
    );
  };

  return {
    etapaSeleccionada,
    siguienteEtapa,
    anteriorEtapa,
    EtapaActual,
  };
};

export default useEtapasFormulario;
