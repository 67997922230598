// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Button } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const PreguntaFrecuente = (faq) => {
  const [mostrar, setMostrar] = useState(true);
  const respuestaRef = useRef(0)
  const [icono, setIcono] = useState(faChevronDown);

  const mostrarRespuesta = () => {
    setMostrar(!mostrar);
    setIcono(mostrar ? faChevronUp : faChevronDown); 
    if (mostrar) {
      respuestaRef.current.style.maxHeight = "1200px"
      respuestaRef.current.style.paddingBottom = "20px"
    } else {
      respuestaRef.current.style.maxHeight = "0px"
      respuestaRef.current.style.paddingBottom = "0px"
    }
  }

  const toggleRespuesta = () => {
    mostrarRespuesta();
  }

  return (
    <div className="pregunta-frecuente" onClick={toggleRespuesta}>
      <div className="pregunta" >
        <img className="icono" src={faq.icono} alt="ERROR" />
        <p>{faq.pregunta}  </p>
        
         
          <FontAwesomeIcon className="boton-preguntas" icon={icono} />
        
      </div>
      <div ref={respuestaRef} className="respuesta"><p>{faq.respuesta}</p></div>
      </div>
  );
};

export default PreguntaFrecuente;
