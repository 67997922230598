import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ContenedorEtapa from "./ContenedorEtapa";
import { DatosEmpresaProvider } from "./DatosEmpresaContext/DatosEmpresaContext";

const DatosEmpresa = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="secuencia-etapas">
        <div onClick={() => navigate("/Portal/Formulario")} className="etapa-secuencia ">
          <FontAwesomeIcon icon={faCheckCircle} />
          <p>Datos Fiscales</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
        <div className="etapa-secuencia activo">
          <FontAwesomeIcon icon={faCircle} />
          <p>Datos de la Empresa</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
        <div onClick={() => navigate("/Portal/Formulario/Datos-Comerciales")} className="etapa-secuencia incompleta">
          <FontAwesomeIcon icon={faCircle} />
          <p>Datos Comerciales</p>
        </div>
      </div>
      <DatosEmpresaProvider>

        <ContenedorEtapa />
      </DatosEmpresaProvider>
    </>
  );
};

export default DatosEmpresa;
