// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Descripcion = () => {
  return (
    <section className="descripcion">
      <div className="fondo">
        <img src="imgs/fondo_contenedor.webp" />
      </div>
      <div className="contenedor-emblema">
        <img src="/imgs/chihuahua_market_tag.webp" />
      </div>

      <div className="contenedor-texto">
        <h3>¿Qué es ¡CHIHUAHUA MARKET!?</h3>

        <p>
          Es un sello distintivo que brinda identidad a las empresas productoras
          y prestadoras de servicios del estado de Chihuahua. Con la finalidad
          de promover las ventas y la competitividad de los productos y
          servicios que cuenten con este distintivo.
        </p>
      </div>
    </section>
  );
};

export default Descripcion;
