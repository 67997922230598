import Verificacion from "../../Componentes/Verificacion/Verificacion";
import AuthProvider from "../AuthContext/AuthContext";

// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { TextField } from "@mui/material";
const VerificarCuenta = () => {
  return (
    <main className="verificacion">
        <h1>¡Verifica tu Correo Electrónico!</h1>
      <div className="contenedor-verificacion">
        <Verificacion />
      </div>
      
    </main>
  );
};

export default VerificarCuenta;
