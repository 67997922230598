// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import Slider from "react-slick";
import { Button } from "@mui/material";
const SliderEscritorio = ({ slides, settings }) => {
  return (
    <div className="contenedor-slider-escritorio">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={`Slide ${index}`} />
              <div className="texto-slider">
                <h3>{slide.content.text}</h3>
                <h3>{slide.content.text2}</h3>
                <h3>{slide.content.text3}</h3>
                <h3>{slide.content.text4}</h3>
              
              <Button
                className="boton"
                variant="contained"
                color="primary"
                href={slide.content.button.link}
              >
                {slide.content.button.label}
              </Button>
              </div>
            </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderEscritorio;
