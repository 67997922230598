import { FormGroup, InputLabel, Checkbox, FormControlLabel } from "@mui/material";
const Etapa1 = ({datosEmpresa,setDatosEmpresa,cargando}) => {
    return ( 
   
                  <FormGroup>
        <InputLabel>
          ¿En qué canales de venta se encuentra actualmente?
        </InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaDirecta}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaDirecta: e.target.checked,
                })
              }
            />
          }
          label="Venta Directa"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaEnLinea}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaEnLinea: e.target.checked,
                })
              }
            />
          }
          label="Venta en línea"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaPorMayor}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaPorMayor: e.target.checked,
                })
              }
            />
          }
          label="Venta por mayor"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaIntermediarios}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaIntermediarios: e.target.checked,
                })
              }
            />
          }
          label="Venta a intermediarios"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaFranquicias}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaFranquicias: e.target.checked,
                })
              }
            />
          }
          label="Venta a franquicias"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={datosEmpresa.VentaDistribuidores}
              onChange={(e) =>
                setDatosEmpresa({
                  ...datosEmpresa,
                  VentaDistribuidores: e.target.checked,
                })
              }
            />
          }
          label="Venta a distribuidores"
        />
      </FormGroup>
 
     );
}
 
export default Etapa1;