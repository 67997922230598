import { Skeleton, TextField } from "@mui/material";

const CampoTexto = ({
  placeholder,
  label,
  valor,
  state,
  setState,
  nombre,
  tipo = "text",
  requerido = false,
  multilinea = false,
  deshabilitado = false,
  cargando,
}) => {
  return (
    <div className="campo-texto">
      {cargando ? (
        <Skeleton height={65}/>
        
      ) : (
        <TextField
        fullWidth
          size="small"
          placeholder={placeholder}
          label={label}
          value={state[nombre]}
          onChange={(e) => setState({ ...state, [nombre]: e.target.value })}
          type={tipo}
          required={requerido}
          multiline={multilinea}
          disabled={deshabilitado}
          name={nombre}
        />
      )}
    </div>
  );
};

export default CampoTexto;
