// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import React from "react";
import { Map, Marker } from "pigeon-maps";
const Mapas = () => {
  return (
    <>
      <div className="Mapas">
        <div className="Mapa-CUU">
          <h2>Chihuahua</h2>
          <Map height={300} defaultCenter={[28.72065, -106.12923]} defaultZoom={15}>
            <Marker onClick={() => window.open("https://www.google.com/maps/place/SIDE/@28.7204478,-106.1292682,15z/data=!4m14!1m7!3m6!1s0x86ea412384727d29:0x28968420ff857755!2sSIDE!8m2!3d28.7204478!4d-106.1292682!16s%2Fg%2F11v3r07kbz!3m5!1s0x86ea412384727d29:0x28968420ff857755!8m2!3d28.7204478!4d-106.1292682!16s%2Fg%2F11v3r07kbz?entry=ttu","_blank")} width={60} anchor={[28.72065, -106.12923]} />
          </Map>
        </div>
        <div className="Mapa-JRZ">
          <h2>Ciudad Juárez</h2>
          <Map height={300} defaultCenter={[31.74986, -106.45207]} defaultZoom={15}>
            <Marker onClick={() => window.open("https://www.google.com.mx/maps/place/Unidad+Administrativa+del+Gobierno+del+Estado+de+Chihuahua+Jos%C3%A9+Mar%C3%ADa+Morelos+y+Pav%C3%B3n/@31.7494829,-106.4525147,19.83z/data=!4m6!3m5!1s0x86e75967ff8512e7:0x75783f9e126829f4!8m2!3d31.7497169!4d-106.4522363!16s%2Fg%2F1tmpkz2_?entry=ttu","_blank")}  width={60} anchor={[31.74986, -106.45207]} />
            </Map>
        </div>

      </div>
    </>
  );
};

export default Mapas;
