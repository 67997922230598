import CampoTexto from "../../../../../Componentes/CampoTexto/CampoTexto";
import Desplegable from "../../../../../Componentes/Desplegable/Desplegable";
const Etapa4 = ({ datosEmpresa, setDatosEmpresa, cargando }) => {
  const CuestionarioSucurales = () => {
    return (
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="CantidadSucursales"
        label="¿Cuántas sucursales tiene?"
        placeholder="0"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
    );
  };
  return (
    < >
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="VentasAnuales"
        label="Ventas anuales"
        placeholder="$0.00"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />

      <Desplegable
        etiqueta="¿La empresa cuenta con sucursales?"
        mostrarContenedor={datosEmpresa}
        setMostrarContenedor={setDatosEmpresa}
        campo={"Sucursales"}
        children={<CuestionarioSucurales />}
      />
    </>
  );
};

export default Etapa4;
