// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import Sector from "./Sector.jsx";
const Sectores = () => {
  const sectores = [
    {
      fondo: "imgs/Alimentos.webp",
      icono: "imgs/Icono-Alimentos.webp",
      contenido: "Alimentos y bebidas",
    },
    {
      fondo: "imgs/Servicios.webp",
      icono: "imgs/Icono-Servicios.webp",
      contenido: "Servicios",
    },
    {
      fondo: "imgs/Restaurante.webp",
      icono: "imgs/Icono-Restaurantes.webp",
      contenido: "Restaurantes",
    },
    {
      fondo: "imgs/Belleza.webp",
      icono: "imgs/Icono-Belleza.webp",
      contenido: "Belleza y cuidado personal",
    },
    {
      fondo: "imgs/Moda.webp",
      icono: "imgs/Icono-Moda.webp",
      contenido: "Moda y calzado",
    },
    {
      fondo: "imgs/Industria.webp",
      icono: "imgs/Icono-Industrial.webp",
      contenido: "Industria",
    },
    {
      fondo: "imgs/Artesanias.webp",
      icono: "imgs/Icono-Artesanias.webp",
      contenido: "Artesanal",
    },
  ];
  return (
    <div className="contenedor-sectores">
      <div className="titulo-sectores">
        <h1>Sectores ¡CHIHUAHUA MARKET! </h1>
      </div>
      <div className="sectores">
        {sectores.map((sector, index) => (
          <Sector
            key={index}
            fondo={sector.fondo}
            icono={sector.icono}
            contenido={sector.contenido}
          />
        ))}
      </div>
      <div className="disclaimer">
        <p>
          Quedan excluidos los giros negros (bares, cantinas, centros nocturnos,
          centro de apuestas, etc.)
        </p>
      </div>
    </div>
  );
};

export default Sectores;
