// Hugo Edibray Becerra Gandara
// Github: @Edibraaay

import { TextField, Button } from "@mui/material";
import Swal from "sweetalert2";


const Contactanos = () => {
    return ( 
        <>
        <div className="Contactanos">
          <h1>Contáctanos</h1>
          <p>
            Si tienes alguna duda, comentario o sugerencia, deja tus datos y nos
            comunicaremos contigo.
          </p>
          <p>O puedes contactarnos a través de nuestras redes sociales.</p>
          <p>¡Estamos para servirte!</p>
            <form className="Formulario">
              <TextField

               required
               variant="outlined"

                fullWidth
                label="Nombre" />
              <TextField
               required 
               variant="outlined"

               fullWidth 
               label="Email" />
              <TextField 
              required 
              variant="outlined"

              fullWidth
               label="Teléfono" />
              <TextField
                required
                variant="outlined"

                fullWidth
                label="¿Como te enteraste de nosotros?"
              />
              <button
                className="boton-contacto"
                onClick={() =>
                  Swal.fire("Funcion no disponible por el momento, intenta contactarnos a través de nuestras redes sociales, correo o teléfono.")
                }
              >
                Enviar
              </button>
            </form>
        </div>
        </>
     );
}
 
export default Contactanos;