import { createContext } from "react";
import { useState } from "react";
const DatosEmpresaContext = createContext();

const DatosEmpresaProvider = ({ children }) => {
  const [datosEmpresa, setDatosEmpresa] = useState({
    FechaOperaciones: false,
    MercadoActual: "",
    VentaDirecta: false,
    VentaEnLinea: false,
    VentaPorMayor: false,
    VentaIntermediarios: false,
    VentaFranquicias: false,
    VentaDistribuidores: false,
    TiendaEnLinea: false,
    URLTiendaEnLinea: "",
    RedesSociales: false,
    Twitter: "",
    Facebook: "",
    Instagram: "",
    LinkedIn: "",
    TamanoEmpresa: "",
    EmpleadosHombres: 0,
    EmpleadosMujeres: 0,
    EmpleadosTotales: 0,
    EmpleadosDiscapacidad: 0,
    VentasAnuales: 0,
    Sucursales: false,
    CantidadSucursales: 0,
  });
  const [cargando, setCargando] = useState(false)
  return (
    <DatosEmpresaContext.Provider
      value={{
        datosEmpresa,
        setDatosEmpresa,
        cargando
      }}
    >
      {children}
    </DatosEmpresaContext.Provider>
  );
};

export { DatosEmpresaProvider };

export default DatosEmpresaContext;
