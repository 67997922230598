import { InputLabel } from "@mui/material";
import CampoTexto from "../../../../../Componentes/CampoTexto/CampoTexto";

const Etapa3 = ({datosEmpresa,setDatosEmpresa,cargando}) => {
    return ( 
        < >
             <InputLabel>Datos de los empleados</InputLabel>
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="TamanoEmpresa"
        label="¿Cuál es el tamaño de la empresa?"
        placeholder="Micro, Pequeña, Mediana, Grande"
        tipo="text"
        
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="EmpleadosHombres"
        label="Número de empleados hombres"
        placeholder="0"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="EmpleadosMujeres"
        label="Número de empleados mujeres"
        placeholder="0"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="EmpleadosTotales"
        label="Número de empleados totales"
        placeholder="0"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
      <CampoTexto
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="EmpleadosDiscapacidad"
        label="Número de empleados con discapacidad"
        placeholder="0"
        tipo="number"
        requerido={true}
        multilinea={false}
        deshabilitado={false}
        cargando={cargando}
      />
        </>
     );
}
 
export default Etapa3;