// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Resumen = () => {
    return ( 
        <section className="resumen">
        <div className="contenedor-texto">
          <h1>¿Qué es ¡CHIHUAHUA MARKET!?</h1>
          <p>
            El distintivo ¡Chihuahua Market! es un medio para identificar el
            talento y la mano de obra de los chihuahuenses buscando privilegiar
            el consumo de los productos y servicios de Chihuahua, brindando un
            sello característico y elevando un sentimiento de pertenencia
            arraigado en la cultura regional.
          </p>
          <button onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/1A2I1gB5d-eDbdzcgdSknK1yVAsHTIDyPpLVXUfsBfG8/viewform?chromeless=1&edit_requested=true",
                "_blank"
              )
            }>¡Aplica Aquí!</button>
        </div>
        <div className="gird-imagenes">
            <img src="/que-es/1.webp" alt="gird" />
            <img src="/que-es/2.webp" alt="gird" />
            <img src="/que-es/1.webp" alt="gird" />
            <img src="/que-es/4.webp" alt="gird" />
            <img src="/que-es/5.webp" alt="gird" />
            <img src="/que-es/6.webp" alt="gird" />
            <img src="/que-es/7.webp" alt="gird" />
            <img src="/que-es/8.webp" alt="gird" />
            <img src="/que-es/2.webp" alt="gird" />

        </div>
      </section>
     );
}
 
export default Resumen;