// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import MercadoLibre from "./MercadoLibre";
import ParaQueSirve from "./ParaQueSirve";
import Beneficios from "./Beneficios";

const Secciones = () => {
  return (
    <section className="secciones">
      <ParaQueSirve />
      <MercadoLibre />
      <Beneficios />
    </section>
  );
};

export default Secciones;
