// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import Mapas from "./Componentes/Mapas";
import Contactanos from "./Componentes/Contacto";

const Contacto = () => {
  return (
    <>
      <div className="Contenedor-contacto">
        <Contactanos />
        <Mapas/>
      </div>
    </>
  );
};

export default Contacto;
