import { Outlet } from "react-router-dom";
import AuthProvider from "../AuthContext/AuthContext";

const Auth = () => {
    return ( 
        <AuthProvider>
       <>
                <Outlet/>
                </>
        </AuthProvider>
     );
}
 
export default Auth;