
import Formulario from "../../Componentes/Formulario/Formulario";
import AuthProvider from "../AuthContext/AuthContext";
// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
const CrearCuenta = () => {

  return (

    <main className="auth">
      <section className="auth-container">
        <div className="contenedor-arte">
          <img src="/auth/login.webp" alt="auth" />
        </div>
        <div className="contenedor-formulario">
          <Formulario/>
        </div>
      </section>
    </main>

    
  );
};

export default CrearCuenta;
