import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import useRequest from "../../Hooks/useRequest/useRequest";
import AuthProvider from "../../Auth/AuthContext/AuthContext";
import useAuth from "../../Auth/AuthContext/useAuth";
import Swal from "sweetalert2";

const Verificacion = () => {
  const verificarCuenta = useRequest("/api/auth/verificar");
  const reenviarCodigo = useRequest("api/auth/reenvio");
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);
  const [reenvioCargando, setReenvioCargando] = useState(false);
  const [reenvioMensaje, setReenvioMensaje] = useState("");
  const { CorreoElectronico, informacion, setInformacion } = useAuth();

  const [errorInformacion, seterrorInformacion] = useState({
    msg: "",
  });

  const ReenvioCodigo = async (e) => {
    e.preventDefault();
    setReenvioCargando(true);

    try {
      const response = await reenviarCodigo.post(informacion);

      if (response.status === 200) {
        setReenvioMensaje("Código reenviado exitosamente");
        Swal.fire({text: "Codigo reenviado correctamente. Verifica tu correo electronico", icon: "success"})
      } else if (response.status === 401) {
        setReenvioMensaje(response.data.message);
      }
    } catch (error) {
      console.error("Error al reenviar código:", error);
      setReenvioMensaje("Error al reenviar código. Inténtalo de nuevo.");
    }

    setReenvioCargando(false);
  };

  const VerificacionCuenta = async (e) => {
    e.preventDefault();
    console.log(CorreoElectronico);
    setCargando(true);
    const response = await verificarCuenta.post(informacion);
    console.log(informacion);
    setCargando(false);
    if (response.status === 200) {
      navigate("/Auth");
    } else if (response.status === 401) {
      setCargando(false);
      if(response.data.TipoError === "Verificacion"){
        seterrorInformacion({
          TipoError: "Verificacion",
          msg: response.data.error,
        });
      }
    }
  };

 



  useEffect(() => {
    console.log(informacion);
  }, []);
  return (
    <>
      <h3>Envío Exitoso</h3>

      <p>
        Enviamos su código de verificación por correo electónico:
        <br />
        <span>  {informacion.CorreoElectronico} </span>
        

      </p>
      <form onSubmit={(e) => VerificacionCuenta(e)} className="input-verificacion">
        <div className="contenido-verificacion">
          <TextField
          
            label={cargando ? "Cargando..." : "Verificar código"}
            required
            backgroundColor="white"
            value={informacion.codigo_verificacion}
            onChange={(e) =>
              setInformacion({
                ...informacion,
                codigo_verificacion: e.target.value,
              })
            }
            error={
              errorInformacion.TipoError == "Verificacion" ? true : false
            }
            helperText={
              errorInformacion.TipoError == "Verificacion"
                ? errorInformacion.msg
                : ""
            }
          />
          <Button  type="submit" variant="contained" disabled={cargando} style={{backgroundColor: "#D6506D"}}>  {cargando ? 'Verificando cuenta...' : 'Verificar Cuenta'}</Button>
        </div>
      </form>
      <div className="texto-reenvio">
      <p>¿Tienes problemas para verificar tu correo?</p>
      <span onClick={ReenvioCodigo} disabled={reenvioCargando}>
        {reenvioCargando ? "Enviando código..." : "Reenviar código"}
      </span>
     
      </div>
    </>
  );
};

export default Verificacion;
