// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faEnvelope,
  faE,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";


function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      
        <div className="footer-logo">
          <img src="/imgs/Logo_Footer.webp" />
        </div>
        <div className="footer-contact">
          <h3>Chihuahua</h3>
          <p>
            <FontAwesomeIcon className="icono" icon={faLocationDot} /> Quijote de la Mancha 1,
            Complejo Industrial Chihuahua, 31136 Chihuahua
          </p>
          <p>
            <FontAwesomeIcon className="icono" icon={faPhone} /> 614-442-3300 ext. 23426, 23329
          </p>
          <p>
            
            <FontAwesomeIcon className="icono" icon={faEnvelope} />
            direcciondecomercioside@gmail.com
          </p>
        </div>
        <div className="footer-contact">
          <h3>Cd. Juárez</h3>
          <p>
            
            <FontAwesomeIcon className="icono" icon={faLocationDot} /> Av. Abraham Lincoln No.
            1320 Fracc. Córdova Américas C.P. 32310
          </p>
          <p>
            
            <FontAwesomeIcon className="icono" icon={faPhone} />  656 629 33 00 Ext: 54900
          </p>
          <p>
            
            <FontAwesomeIcon className="icono" icon={faEnvelope} />
              direcciondecomercioside@gmail.com
          </p>
        </div>
        <div className="footer-links">
          <p onClick={() => { navigate("/Que-Es"); window.scrollTo(0, 0); }}>Nosotros</p>
          <p  onClick={() => { navigate("/FAQ"); window.scrollTo(0, 0); }}>FAQ</p>
          <p onClick={() => { navigate("/Contacto"); window.scrollTo(0, 0); }}>Contáctanos</p>
          <p onClick={() =>
              window.open(
                "https://tienda.mercadolibre.com.mx/chihuahua-market",
                "_blank"
              )
            }>Tienda Oficial </p>
        </div>
    </footer>
  );
}

export default Footer;
