// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import PreguntasFrecuentes from "./Componentes/PreguntasFrecuentes";

const Faq = () => {
    return (
        <section className="main">
            <PreguntasFrecuentes />
        </section>
    );
};
export default Faq;