// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { Outlet } from "react-router-dom";
import Footer from "../../Componentes/Footer/Footer";
import Header from "../../Componentes/Header/Header";

const Estructura = () => {
    return ( 
        <main>
            <Header />
            <Outlet />
            <Footer />
        </main>
     );
}
 
export default Estructura;