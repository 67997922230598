import OpcionMultiple from "../../../../Componentes/OpcionMultiple/OpcionMultiple";
import CampoTexto from "../../../../Componentes/CampoTexto/CampoTexto";
import Colonias from "../../../../Componentes/Colonias/Colonias";
import { Button } from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const DatosFiscales = () => {
  const navigate = useNavigate();
    const [cargando, setCargando] = useState(false);
    const personalidades = [
        { Id: 1, Personalidad: "Persona Física" },
        { Id: 2, Personalidad: "Persona Moral" },
      ];
    
      const [datosFiscales, setDatosFiscales] = useState({
        Personalidad: 1,
        RazonSocial: "",
        NombreComercial: "",
        RFC: "",
        RepresentanteLegal: "",
        Telefono: "",
        Calle: "",
        NumeroExterior: "",
        NumeroInterior: "",
        Colonia: "",
        CodigoPostal: "",
        Municipio: "",
      });
    return ( 
      <>
      <div className="secuencia-etapas">
        <div  className="etapa-secuencia activo">
        <FontAwesomeIcon icon={faCheckCircle} />
        <p>Datos Fiscales</p>
        </div>
       <FontAwesomeIcon icon={faChevronRight} />
        <div  onClick={() => navigate("/Portal/Formulario/Datos-Empresa")}className="etapa-secuencia incompleta">
        <FontAwesomeIcon icon={faCircle} />
        <p>Datos de la Empresa</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
        <div onClick={() => navigate("/Portal/Formulario/Datos-Comerciales")} className="etapa-secuencia incompleta">
        <FontAwesomeIcon icon={faCircle} />
        <p>Datos Comerciales</p>
        </div>
      </div>
      <div className="etapa">
        <form>
        <h1>Datos Fiscales</h1>
        <p>Llena los siguientes campos con la información de tu Constancia de Situación Fiscal</p>
        <OpcionMultiple
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="Personalidad"
          elementos={personalidades}
          identificador={"Id"}
          valor={"Personalidad"}
          etiqueta={"La empresa es:"}
        />
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="RepresentanteLegal"
          label={datosFiscales.Personalidad == 2 ? "¿Cuál es el nombre del representante legal?" : "¿Cuál es el nombre del solicitante?"}
          placeholder={"Juan Pérez"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        {datosFiscales.Personalidad == 2 ? (
          <CampoTexto
            state={datosFiscales}
            setState={setDatosFiscales}
            nombre="RazonSocial"
            label="Razón Social"
            placeholder="Empresa S.A. de C.V"
            tipo="text"
            requerido={true}
            multilinea={false}
            deshabilitado={false}
            cargando={cargando}
          />
        ) : null}
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="NombreComercial"
          label="¿Cuál es el nombre comercial de la empresa?"
          placeholder={"Negocio"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="RFC"
          label="RFC"
          placeholder={"XXXX000000XXX"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />

        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="Telefono"
          label="Teléfono"
          placeholder={"0000000000"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="Calle"
          label="Calle"
          placeholder={"Domicilio Fiscal"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="NumeroExterior"
          label="Número Exterior"
          placeholder={"000"}
          tipo="text"
          requerido={true}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />
        <CampoTexto
          state={datosFiscales}
          setState={setDatosFiscales}
          nombre="NumeroInterior"
          label="Número Interior"
          placeholder={"000"}
          tipo="text"
          requerido={false}
          multilinea={false}
          deshabilitado={false}
          cargando={cargando}
        />

        <Colonias
          state={datosFiscales}
          setState={setDatosFiscales}
          campoColonia={"Colonia"}
          campoCp={"CodigoPostal"}
          campoMunicipio={"Municipio"}
          cargando={cargando}
        />
         <Button onClick={() => navigate("Datos-Empresa")} variant="contained">Continuar</Button>
      </form>
      </div>
      </>
     );
}
 
export default DatosFiscales;