import { InputLabel } from "@mui/material";
import { useEffect } from "react";

const OpcionMultiple = ({
  elementos,
  identificador,
  valor,
  nombre,
  state,
  setState,
  etiqueta
}) => {

  return (
    <div className="opcion-multiple">
      <InputLabel>{etiqueta}</InputLabel>
      <div className="contenedor-opciones">
        {elementos.map((elemento) => (
          <div
            key={elemento[identificador]}
            onClick={() =>
              setState({ ...state, [nombre]: elemento[identificador] })
     
            }
            className={
              state[nombre] == elemento[identificador]
                ? "opcion seleccion"
                : "opcion"
            }
          >
            {elemento[valor]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpcionMultiple;
