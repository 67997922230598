// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import "react-slideshow-image/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderEscritorio from "./Componentes/SliderEscritorio";
import SliderMovil from "./Componentes/SliderMovil";
import PopUp from "./Componentes/PopUp";
import Descripcion from "./Componentes/Descripcion";

import Sectores from "./Componentes/Sectores/Sectores";
import Pasos from "./Componentes/Pasos/Pasos";
import Vigencia from "./Componentes/Vigencia";
import Secciones from "./Componentes/Secciones/Secciones";

const Inicio = () => {
  const slides = [
    {
      image: "imgs/carrusel_1.webp",
      content: {
        text: "CONSUME LOCAL, ELIGE",
        text2: "CHIHUAHUA",
        button: {
          label: "IR A LA TIENDA",
          link: "https://tienda.mercadolibre.com.mx/chihuahua-market",
        },
      },
    },
    {
      image: "imgs/carrusel_2.webp",
      content: {
        text: "ENCUENTRA LA",
        text2: "TIENDA OFICIAL DE",
        text3: "!CHIHUAHUA MARKET!",
        text4: "MERCADO LIBRE",
        button: {
          label: "OBTEN TU DISTINTIVO",
          link: "https://docs.google.com/forms/d/1A2I1gB5d-eDbdzcgdSknK1yVAsHTIDyPpLVXUfsBfG8/viewform?chromeless=1&edit_requested=true",
        },
      },
    },
    {
      image: "imgs/carrusel_3.webp",
      content: {
        text: "¡DESTACA CON ORGULLO!",
        text2: "OBTEN TU DISTINTIVO",
        text3: "!CHIHUAHUA MARKET!",
        button: {
          label: "IR A TIENDA",
          link: "https://tienda.mercadolibre.com.mx/chihuahua-market",
        },
      },
    },
  ];

  const imagenes = [
    {
      image: "imgs/carrusel-responsivo-1.webp",
      content: {
        text: "CONSUME LOCAL, ELIGE",
        text2: "CHIHUAHUA",
      },
    },
    {
      image: "imgs/carrusel-responsivo-2.webp",
      content: {
        text: "ENCUENTRA LA",
        text2: "TIENDA OFICIAL DE",
        text3: "!CHIHUAHUA MARKET!",
        text4: "MERCADO LIBRE",
      },
    },
    {
      image: "imgs/carrusel-responsivo-3.webp",
      content: {
        text: "¡DESTACA CON ORGULLO!",
        text2: "OBTEN TU DISTINTIVO",
        text3: "!CHIHUAHUA MARKET!",
      },
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <div className="main">
      <SliderEscritorio settings={settings} slides={slides} />
      <SliderMovil settings={settings} imagenes={imagenes} />

      <Descripcion />
      <Secciones />
      <Sectores />
      <Pasos />
      <Vigencia />
    </div>
  );
};
export default Inicio;
