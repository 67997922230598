import { TextField } from "@mui/material";
import DatePicker from "../../../../../Componentes/DatePicker/DatePicker";
import OpcionMultiple from "../../../../../Componentes/OpcionMultiple/OpcionMultiple";
const Etapa0 = ({datosEmpresa,setDatosEmpresa}) => {
  return (
   <>

      <DatePicker
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="FechaOperaciones"
        etiqueta="¿Cuándo inició operaciones el negocio?"
      />
      <OpcionMultiple
        state={datosEmpresa}
        setState={setDatosEmpresa}
        nombre="MercadoActual"
        elementos={[
          { Id: 1, Nombre: "Local" },
          { Id: 2, Nombre: "Estatal" },
          { Id: 3, Nombre: "Nacional" },
          { Id: 4, Nombre: "Internacional" },
        ]}
        identificador={"Id"}
        valor={"Nombre"}
        etiqueta={"¿En qué mercado se encuentra actualmente?"}
      />
    </>
  );
};

export default Etapa0;
