// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
import { useState } from "react";
import PreguntaFrecuente from "./PreguntaFrecuentre";
const PreguntasFrecuentes = () => {
  const [mostrar, setMostrar] = useState(false);




  const faqs = [
    { 
      icono: "imgs/icono1.webp",
      pregunta: "¿Quién emite el distintivo Chihuahua Market?",
      respuesta:
        "La Secretaría de Innovación y Desarrollo Económico del Gobierno del Estado de Chihuahua, através de su Dirección de Emprendimiento y Desarrollo Empresarial, ostenta la titularidad exclusiva y la autoridad para otorgar el distintivo  \"Chihuahua Market\"",  
    },
    {
      icono: "imgs/icono2.webp",
      pregunta:
        "¿El distintivo tiene una fecha de vencimiento o requiere renovación periódica? ",
      respuesta:
        "El distintivo \"Chihuahua Market\" tiene una validez de seis años, al término de los cuales se debe realizar una nueva solicitud para obtener nuevamente el distintivo.",
    },
    {
      icono: "imgs/icono3.webp",
      pregunta:
        "¿Qué sucede si no cumplo con todos los requisitos para obtener el distintivo? ",
      respuesta:
        "Si no se cumplen con todos los requisitos necesarios para obtener el distintivo, es probable que no puedas obtenerlo en ese momento, pero podemos trabajar en los documentos faltantes para cumplir con los requisitos establecidos y buscar obtener el distintivo más adelante.",
    },
    {
      icono: "imgs/icono4.webp",
      pregunta:
        "¿El distintivo es transferible o está ligado a una persona o entidad específica? ",
      respuesta:
        "El distintivo no es transferible, únicamente lo podrá utilizar la empresa y el producto o servicio que se le fue autorizado",
    },
    {
      icono: "imgs/icono5.webp",
      pregunta:
        "¿El distintivo requiere algún tipo de capacitación o formación adicional? ",
      respuesta:
        "No se requiere ningún tipo de capacitación únicamente cumplir con los requisitos, documentos, llenado de la solicitud y el cuestionario de autodiagnóstico.",
    },
    {
      icono: "imgs/icono6.webp",
      pregunta:
        "¿Dónde puedo obtener más información o aclarar dudas sobre el distintivo? ",
      respuesta:
        "Se pueden comunicar al teléfono 614-442-3300 ext. 23426, 23329, o vía correo electrónico: direcciondecomercioside@gmail.com",
    },
    {
      icono: "imgs/icono7.webp",
      pregunta:
        "¿Puedo aplicar al distintivo si no me encuentro dado de alta en hacienda o mi empresa cuenta con menos de 1 año en operación?  ",
      respuesta: "El requisito de estar dado de alta en Hacienda y operando un negocio durante un periodo mínimo de un año generalmente se establece con el fin de garantizar la legitimidad y  estabilidad del negocio por lo que recomendable es esperar a que el negocio cumpla con eltiempo establecido.",
    },
  ];

  return (
    <section className="seccion-1">
      <div className="titulo">
        <h1>Preguntas Frecuentes</h1>
      </div>
      <div className="preguntas">
      {faqs.map((faq, index) => (
          <PreguntaFrecuente key={index} icono={faq.icono} pregunta={faq.pregunta} respuesta={faq.respuesta} />
        ))}
      </div>
    </section>
  );
};

export default PreguntasFrecuentes;
