import {
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useRef, useState} from "react";
const Desplegable = ({children, mostrarContenedor,setMostrarContenedor,campo, etiqueta}) => {
  const contenedorRef = useRef(0);


  const toggleMostrarContenedor = () => {
    if (!mostrarContenedor[campo]) {
      contenedorRef.current.style.maxHeight = "0px";
    } else {
      contenedorRef.current.style.maxHeight = "1000px";
    }
  };
  useEffect(() => {
    toggleMostrarContenedor();
  }, [mostrarContenedor[campo]]);
  return (
    <div className="desplegable">
      <div className="controlador">
        <InputLabel>{etiqueta}</InputLabel>
        <ToggleButtonGroup
          value={mostrarContenedor[campo]}
          color="primary"
          size="small"
        >
          <ToggleButton
            onClick={(e) => setMostrarContenedor({...mostrarContenedor, [campo] :true})}
            value={true}
          >
            Si
          </ToggleButton>
          <ToggleButton
            onClick={(e) => setMostrarContenedor({...mostrarContenedor, [campo] :false})}
            value={false}
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div ref={contenedorRef} className="contenedor-desplegable">
       {children}
      </div>
    </div>
  );
};

export default Desplegable;
