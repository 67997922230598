import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRequest from "../../Hooks/useRequest/useRequest";
import useAuth from "../../Auth/AuthContext/useAuth";
import AuthProvider from "../../Auth/AuthContext/AuthContext";

import axios from "axios";
const Formulario = () => {
    const crearCuenta = useRequest("/api/auth/registrar");
    const { setCorreoElectronico, informacion,setInformacion } = useAuth();
    const { CorreoElectronico} = useAuth();
    const navigate = useNavigate();
    
    const [cargando, setCargando] = useState(false);

  
    const [errorInformacion, seterrorInformacion] = useState({
      TipoError: false,
      msg: "",
    });
  
    const CreacionCuenta = async (e) => {
      e.preventDefault();
      setCargando(true);
      console.log(informacion);
      const response = await crearCuenta.post(informacion);
      setCargando(false);
      if (response.status === 201) {

        console.log(informacion);
        console.log(CorreoElectronico);
        console.log("xd")
        navigate("/Auth/Verificar-Cuenta");
      }
      else if (response.status === 401) {
        console.log(response.data);
        if(response.data.TipoError === "Nombre"){
          seterrorInformacion({
            TipoError: "Nombre",
            msg: response.data.error,
          });
        }else if(response.data.TipoError === "CorreoElectronico"){
          seterrorInformacion({
            TipoError: "CorreoElectronico",
            msg: response.data.error,
          });
        }
        else if(response.data.TipoError === "Contrasena"){
          seterrorInformacion({
            TipoError: "Contrasena",
            msg: response.data.error,
          });
        }
      }
    };
  
    useEffect(() => {
      seterrorInformacion({
        TipoError: false,
        msg: "",
      });
    }, [informacion]);
    return (
        <>
          <form onSubmit={(e) => CreacionCuenta(e)}>
            <div className="contenedor-movil" style={{display:"none"}}>
            <h1>  Bienvenido a <span>!Chihuahua Market!</span></h1>

            </div>
            <h2>Crear una cuenta gratis</h2>
            <TextField
              label="Nombre"
              required
              value={informacion.nombre}
              onChange={(e) =>
                setInformacion({ ...informacion, nombre: e.target.value })
              }
              placeholder="Nombre"
              error={errorInformacion.TipoError == "Nombre" ? true : false}
              helperText={errorInformacion.TipoError == "Nombre" ? errorInformacion.msg : ""}
            />
            <TextField
              label="Correo Electrónico"
              required
              value={informacion.CorreoElectronico}
              onChange={(e) =>
                setInformacion({ ...informacion, CorreoElectronico: e.target.value })
              }
              placeholder="correo@ejemplo.com"
              error={errorInformacion.TipoError == "CorreoElectronico" ? true : false}
              helperText={errorInformacion.TipoError == "CorreoElectronico" ? errorInformacion.msg : ""}

            />
            <TextField
              label="Contraseña"
              required
              value={informacion.contrasena}
              onChange={(e) =>
                setInformacion({ ...informacion, contrasena: e.target.value })
              }
              type="password"
              placeholder="Contraseña"
              error={errorInformacion.TipoError == "Contrasena" ? true : false}
              helperText={errorInformacion.TipoError == "Contrasena" ? errorInformacion.msg : ""}
            />

            <Button type="submit" variant="contained" disabled={cargando} style={{backgroundColor: "#D6506D"}}>  {cargando ? 'Creando cuenta...' : 'Crear Cuenta'}</Button>
          </form>
          <hr />
          <p>
            ¿Ya tienes una cuenta?
            
          </p>
          <p>
          <span onClick={() => navigate("/Auth")}>Inicia Sesión</span>
          </p>
        </>
      );
}
 
export default Formulario;