import { TextField } from "@mui/material";
import useRequest from "../../Hooks/useRequest/useRequest";
import { useState } from "react";
import Swal from "sweetalert2";
import { Button } from "@mui/material";



const Recuperacion = () => {
  const verificarCuenta = useRequest("/api/auth/recuperar");
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);

  const [informacion, setInformacion] = useState({
    CorreoElectronico: "",

  });

  const [informacionContrasena, setInformacionContrasena] = useState({
    codigo_verificacion: "",
    contrasena: "",
    contrasenaValidadora: "",
    CorreoElectronico: "",
  });


  const [errorInformacion, seterrorInformacion] = useState({
    TipoError: false,
    msg: "",
  });

  const recuperacionCuenta  = async (e) => {
    setCargando(true);
    e.preventDefault();
    console.log(informacion.CorreoElectronico);
    const response = await verificarCuenta.post(informacionContrasena);
    if (response.status === 200) {
      console.log("xd")
      Swal.fire({ text: "Codigo enviado correctamente al correo proporcionado", icon: "success" });
      setCargando(false);
      document.querySelector(".recuperacion-correo").style.display = "none";
      document.querySelector(".recuperacion-contrasena").style.display = "flex";



    }
    else if (response.status === 401) {
      setCargando(false);
      if(response.data.TipoError === "Usuario"){
        seterrorInformacion({
          TipoError: "Usuario",
          msg: response.data.error,
        });
      }
      
    }


  }

  const definirContrasenaNueva = async (e) => {
    setCargando2(true);
    e.preventDefault();
    console.log(informacionContrasena)
    const response = await verificarCuenta.put(informacionContrasena);
    if (response.status === 200) {
      console.log("xd")
      Swal.fire({ text: "Contraseña cambiada correctamente", icon: "success" });
      setCargando2(false);
    }
    else if (response.status === 401) {
      setCargando2(false);
      if(response.data.TipoError === "Contrasena"){
        seterrorInformacion({
          TipoError: "Contrasena",
          msg: response.data.error,
        });
      }
      if(response.data.TipoError === "Codigo"){
        seterrorInformacion({
          TipoError: "Codigo",
          msg: response.data.error,
        });
      }
    }
  }


    return ( 
        <>
        <div className="recuperacion-correo">
        <p>
          Ingrese su correo electrónico para enviarle un código de verificación
        </p>
          <form className="input-recuperacion" onSubmit={(e) => recuperacionCuenta(e)}>
            <div className="contenido-recuperacion">
              <TextField
                label="Correo Electrónico"
                required
                type="email"
                backgroundColor="white"
                value={informacionContrasena.CorreoElectronico}
                onChange={(e) =>
                  setInformacionContrasena({
                    ...informacion,
                    CorreoElectronico: e.target.value,
                  })
                  
                }
                error={
                  errorInformacion.TipoError == "Usuario" ? true : false
                }
                helperText={
                  errorInformacion.TipoError == "Usuario"
                    ? errorInformacion.msg
                    : ""
                }
              />
            </div>
            <Button style={{backgroundColor: "#D6506D"}} type="submit" variant="contained" disabled={cargando}>
              {cargando ? "Enviando..." : "Enviar codigo"}
            </Button>
          </form>
        </div>
        <div className="recuperacion-contrasena">
        <p>
          Ingrese el código de verificación que se le ha enviado a su correo
          electrónico
        </p>
        <form className="input-verificacion" onSubmit={(e) => definirContrasenaNueva(e)}>
          <div className="contenido-verificacion" >
          <TextField
                label="Codigo Verificación"
                required
                backgroundColor="white"
                value={informacionContrasena.codigo_verificacion}
                onChange={(e) =>
                  setInformacionContrasena({
                    ...informacionContrasena,
                    codigo_verificacion: e.target.value,
                  })
                }
                error={
                  errorInformacion.TipoError == "Codigo" ? true : false
                }
                helperText={
                  errorInformacion.TipoError == "Codigo"
                    ? errorInformacion.msg
                    : ""
                }
              />
            <TextField
                label="Contraseña nueva"
                required
                className="input-contrasena"
                type="password"
                backgroundColor="white"
                value={informacionContrasena.contrasena}
                onChange={(e) =>
                  setInformacionContrasena({
                    ...informacionContrasena,
                    contrasena: e.target.value,
                  })
                }
                error={
                  errorInformacion.TipoError == "Contrasena" ? true : false
                }
                helperText={
                  errorInformacion.TipoError == "Contrasena"
                    ? errorInformacion.msg
                    : ""
                }
              />
            <TextField
                label="Verificar Contraseña Nueva"
                required
                type="password"
                className="input-contrasena"
                backgroundColor="white"
                value={informacionContrasena.contrasenaValidadora}
                onChange={(e) =>
                  setInformacionContrasena({
                    ...informacionContrasena,
                    contrasenaValidadora: e.target.value,
                  })
                }
                error={
                  errorInformacion.TipoError == "Contrasena" ? true : false
                }
                helperText={
                  errorInformacion.TipoError == "Contrasena"
                    ? errorInformacion.msg
                    : ""
                }
                
              />
                      <Button style={{backgroundColor: "#D6506D"}} type="submit" variant="contained" disabled={cargando2}>
              {cargando2 ? "Cambiando contraseña..." : "Cambiar contraseña"}
            </Button>

          </div>
        </form>
      </div>
        
        </>
     );
}
 
export default Recuperacion;