// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Colaboradores = () => {
    return ( 
        <section className="colaboradores">
            <img src="/que-es/mercadoLibre.svg" alt="rsas" />
            <img src="/que-es/alibaba.svg" alt="rsas" />
            <img src="/que-es/enko.png" alt="rsas" />
        </section>
     );
}
 
export default Colaboradores;