import { Outlet } from "react-router-dom";
import Landing from "../Landing/Landing";
import NavBar from "./NavBar";

const EstructuraPortal = () => {
    return ( 
        <main className="portal">
            <NavBar/>
            <Outlet/>
        </main>
     );
}
 
export default EstructuraPortal;