// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
const Beneficios = () => {
  return (
    <div className="seccion">
      <div className="contenedor-imagen">
        <img src="imgs/header_seccion_3.webp" alt="arte-seccion" />
      </div>
      <div className="cuerpo-seccion">
        <div className="titulo-beneficios">
          <h3>¿Que beneficios obtengo?</h3>
        </div>
        <div className="contenido-beneficios">
          <ul>
            <li> ●Venta en ferias locales, nacionales, internacionales y en linea.</li>
            <li> ●Networking y encuentros empresariales.</li>
            <li> ●Cursos y capacitaciones.</li>
            </ul>          
        </div>
      </div>
    </div>
  );
};

export default Beneficios;
