import useEtapasFormulario from "../../../../Hooks/useEtapasFormulario/useEtapasFormulario";
import useDatosEmpresa from "./DatosEmpresaContext/useDatosEmpresa";
import Etapa0 from "./Etapas/Etapa0";
import Etapa1 from "./Etapas/Etapa1";
import Etapa2 from "./Etapas/Etapa2";
import Etapa3 from "./Etapas/Etapa3";
import Etapa4 from "./Etapas/Etapa4";
const ContenedorEtapa = () => {

    const {datosEmpresa,setDatosEmpresa,cargando} = useDatosEmpresa();
    const etapas = [
        <Etapa0
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          cargando={cargando}
          
        />,
        <Etapa1
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          cargando={cargando}
        />,
        <Etapa2
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          cargando={cargando}
        />,
        <Etapa3
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          cargando={cargando}
        />,
        <Etapa4
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          cargando={cargando}
        />,
      ];
    
      const useEtapas = useEtapasFormulario(etapas);
    return ( 
        <>
           <useEtapas.EtapaActual />
        </>
     );
}
 
export default ContenedorEtapa;