import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  const formularioRef = useRef(0);
  const cuestionarioRef = useRef(0);
  const documentacionRef = useRef(0);

  const [etapaSolicitud, setEtapaSolicitud] = useState(0);
  const [errorEtapa, setErrorEtapa] = useState(false);

  const redirigirDiagnostico = () => {

    setErrorEtapa(true);
    formularioRef.current.scrollIntoView({ behavior: "smooth" });
    //Agregar clase
    formularioRef.current.classList.add("alerta");
    cuestionarioRef.current.classList.add("bloqueado");
    documentacionRef.current.classList.add("bloqueado");
    setTimeout(() => {
      
      formularioRef.current.classList.remove("alerta");
    }, 3000);
  };

  return (
    <section className="landing-portal">
      <h1>Bievenido, nombre del solicitante</h1>
      {errorEtapa ? (
        <p className="error-alerta">Debes llenar el formulario de registro</p>
      ) : null}

      <div className="contenedor-etapas-solicitud">
        <div onClick={() => navigate("Formulario")} ref={formularioRef} className="etapa-solicitud">
          <h2>1</h2>
          <p>Formulario de Registro</p>
        </div>
        <div ref={cuestionarioRef} onClick={() => redirigirDiagnostico()} className="etapa-solicitud">
          <h2>2</h2>
          <p>Cuestionario de Autodiagnóstico</p>
        </div>{" "}
        <div ref={documentacionRef} className="etapa-solicitud">
          <h2>3</h2>
          <p>Documentación</p>
        </div>
      </div>
    </section>
  );
};

export default Landing;
